<template>
  <v-container v-if="!!scPayload" :id="scPayload.slug" class="pa-0 pt-16 pb-16">
    <v-row justify="center">
      <v-col id="scrollTarget" cols="12" md="6">
        <H2Number
          :number="scPayload.title_has_number ? `${scPayload.title_number}` : ''"
          :title="`${activeTranslationWrapper?.title}`"
          :class="{ 'text-center': mdAndUp }" />
      </v-col>
    </v-row>

    <v-row class="d-flex flex-row justify-center">
      <v-col
        v-for="(solutionCardItem, index) in scPayload.solution_card_items"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        xl="4">
        <CdeCard width="100%" height="100%">
          <v-img
            max-width="100%"
            :src="`https://epd.directus.app/assets/${solutionCardItem.solution_card_id.image}?format=webp`"
            :srcset="`https://epd.directus.app/assets/${solutionCardItem.solution_card_id.image}?format=webp 1x, https://epd.directus.app/assets/${solutionCardItem.solution_card_id.image}?format=webp 2x`"
            :alt="solutionCardItem.solution_card_id.image_alt_text || ''" />
          <v-card-item>
            <v-card-title class="card-header">
              {{ activeTranslationCard(solutionCardItem.solution_card_id)?.title }}
            </v-card-title>
          </v-card-item>

          <v-card-item>
            <v-card-text class="px-0 pt-0">
              {{ activeTranslationCard(solutionCardItem.solution_card_id)?.description }}
            </v-card-text>
          </v-card-item>
        </CdeCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>
import type { LocaleObject } from '@nuxtjs/i18n'
import { useDisplay } from 'vuetify'
import type { Cards, CardsTranslation, SolutionCard, SolutionCardTranslation } from '~/types/types'

const { locale, locales } = useI18n()

const props = defineProps<{
  scPayload: Cards
}>()

const { mdAndUp } = useDisplay()

const solutionCardPayload = props.scPayload

const currentLocaleIso = computed(() => {
  return locales.value?.find((l: LocaleObject) => l.code === locale.value)?.language as string
})

const activeTranslationWrapper = computed(() => {
  return solutionCardPayload.translations?.find(
    (t: CardsTranslation) => t.languages_code === currentLocaleIso.value,
  )
})
function activeTranslationCard(card: SolutionCard) {
  return card.translations?.find(
    (t: SolutionCardTranslation) => t.languages_code === currentLocaleIso.value,
  )
}
</script>

<style lang="scss" scoped>
.card-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 800;
}

.mdi-chevron {
  font-size: 2.4rem;
}

.d-none {
  // utility class
  display: none !important;
}

@media (max-width: 1279px) {
  .mobile-btn {
    font-size: 0.9rem;
  }
  :deep(.v-btn__content) {
    white-space: pre-wrap;
  }
}

@media (max-width: 599px) {
  .card-header {
    font-size: 18px;
    line-height: normal;
  }
  .mobile-btn {
    font-size: 0.75rem;
    padding: 12px 8px;
  }
}
</style>
